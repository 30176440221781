
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(100);

        $('html').addClass('loaded');
        
        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Popup
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        // open video popup
        $('.open-video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // Open login
        $('.open-popup-link').magnificPopup({
            type:'inline',
            midClick: true
        });

        function typeWrite(elemento){
            const textoArray = elemento.innerHTML.split('');
            elemento.innerHTML = ' ';
            textoArray.forEach(function(letra, i){   
              
            setTimeout(function(){
                elemento.innerHTML += letra;
            }, 75 * i)

          });
        }
        const titulo = document.querySelector('.typeWrite');
        typeWrite(titulo);


        // owl carousel
        $('[data-component=itens]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 3,
                center          : true,
                autoWidth       : true,
                autoplay        : true,
                nav             : true,
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : false,
            });
        });

        $('#area_cliente').each(function(index, el) {
            $('#area_cliente .forms').on('click', 'li > a', function(event) {
                event.preventDefault();

                target = $(this).attr('href');

                $('#area_cliente .forms li > a').removeClass('_active');
                $('#area_cliente .forms .Form').addClass('_hide');
                
                $(this).addClass('_active');
                $(target).removeClass('_hide');

            });
        });

        // Formulário de pré cadastro
        var validator = $( "#form_cadastro" ).validate({
            errorClass: "_error",
            errorElement: "span",
        });

        $("section[data-step]").hide();
        $("Button._outline").hide();
        $("section[data-step=1]").show();
        $("input[type='submit']").click(function(e) {
            e.preventDefault();
            var step = $("#form_cadastro").data("step");
            var isValid = true;
            $("section[data-step='" + step + "'] input[required='required']").each(function(idx, elem) {
                $(elem).parent('_checkbox').removeClass("_error");
                
                validator.element(elem);

                if($(elem).hasClass("_error")) {
                    isValid = false;

                    $(elem).parent('._checkbox').addClass("_error");
                }

            });
            if(isValid) {
                step += 1;
                if(step > $("section[data-step]").length) {
                    var dados = new FormData($('#form_cadastro')[0]);
                    
                    // loader
                    $('#form_cadastro').html('<svg style="display:block; margin: 0 auto;" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="loading" style="background: none;"><g transform="rotate(28.345 50 50)"><path d="M50 15A35 35 0 1 0 74.787 25.213" fill="none" ng-attr-stroke="{{config.color}}" ng-attr-stroke-width="{{config.width}}" stroke="#28292f" stroke-width="12"/><path ng-attr-d="{{config.darrow}}" ng-attr-fill="{{config.color}}" d="M49 3L49 27L61 15L49 3" fill="#28292f"/><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/></g></svg><p class="_tac">Enviando...</p>');

                    $.ajax({
                        url: $('#form_cadastro').attr('action'),
                        type: "POST",
                        data: dados,
                        processData: false,
                        cache: false,
                        contentType: false,
                        success: function (e) {
                            console.log('success');
                            $('#form_cadastro').html('<p>Pré cadastro concluído.</p><p>Estamos analisando os seus dados, um dos nossos analístas vai entrar em contato em até 48 horas.*</p><p>Muito obrigado por escolher as soluções da Aticca.</p>')
                                .hide()
                                .fadeIn(1500);
                            $('#form_cadastro').trigger('reset');
                        },
                        error: function (e) {
                            console.log('error');
                            $( "#form_cadastro" ).after("<div class='Message _error _w100'><h5>Ocorreu um erro ao enviar sua mensagem. Tente novamente mais tarde.</h5></div>")
                                .hide()
                                .fadeIn(1500);
                        }
                    });
                }
                $("#form_cadastro").data("step", step);
                $("section[data-step]").hide();
                $("section[data-step='" + step + "']").show();
                $("Button._outline").show();
            }
        });
        
        $("Button._outline").click(function(e) {
            e.preventDefault();
            var step = $("#form_cadastro").data("step");
            step -= 1;
            $("#form_cadastro").data("step", step);
            $("section[data-step]").hide();
            $("section[data-step='" + step + "']").show();
            if(step === 1) {
                $("Button._outline").hide();
            }
        });
    });
});


